import useMediaQuery from "../../utils/useMediaQuery";
import Container from ".";
import { IoMdArrowDropleft } from "react-icons/io";

const ContainerWithTitle = ({ title, children, width }) => {
  const { isXs, isSm } = useMediaQuery();
  let wd = width ? width : isXs ? "95vw" : "50vw";
  return (
    <div style={{ padding: "20px 0" }}>
      <a href="/" style={{ display: "flex", alignItems: "center" }}>
        <IoMdArrowDropleft size={35} />
        <h4 className="bolder m0 link">BACK TO HOME</h4>
      </a>
      <Container width={wd} height={isXs ? "300px" : "100%"}>
        <div style={{ marginBottom: "2rem" }}>
          <h1
            className="bolder m0"
            style={{ fontSize: isSm ? "60px" : "80px", letterSpacing: "2px" }}
          >
            {title}
          </h1>
        </div>
        {children}
      </Container>
    </div>
  );
};

export default ContainerWithTitle;
