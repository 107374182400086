import React from "react";
import App from "./views/App";
import About from "./views/About";
import Resume from "./views/Resume";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Graphics from "./views/Graphics";
import Photography from "./views/Photography";
import Grids from "./views/Grids";
import useFontLoaded from "./utils/useIsFontLoaded";

const Router = () => {
  const fontloaded = useFontLoaded("coolvetica");
  if (!fontloaded)
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
        }}
      ></div>
    );
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/photography" element={<Photography />} />
          <Route path="/graphics" element={<Graphics />} />
          <Route path="/elearning" element={<Grids />} />
          <Route path="/videography" element={<Grids />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
