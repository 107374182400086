import { useEffect, useState } from "react";

const useFontCheck = (fontName, checkInterval = 100) => {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      document.fonts.load(`1em "${fontName}"`).then((fonts) => {
        if (fonts.length >= 1 && !fontLoaded) {
          setFontLoaded(true);
          clearInterval(interval);
        }
      });
    }, checkInterval);

    return () => clearInterval(interval);
  }, [fontName, fontLoaded, checkInterval]);

  return fontLoaded;
};

export default useFontCheck;
