import React from "react";
import ReactDOM from "react-dom/client";
import "normalize.css";
import "./styles/globals.scss";
import "./styles/typography.scss";
import { Helmet } from "react-helmet";
import Router from "./Router";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/bao6rrm.css" />
    </Helmet>
    <Router />
  </React.StrictMode>
);
