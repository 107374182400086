import { useState, useEffect } from "react";

const useMediaQuery = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getWidthClass = () => {
    if (width < 576) return "xs";
    if (width >= 576 && width < 768) return "sm";
    if (width >= 768 && width < 992) return "md";
    if (width >= 992) return "lg";
  };

  const isXs = getWidthClass() === "xs";
  const isSm = isXs || getWidthClass() === "sm";
  const isMd = isSm || getWidthClass() === "md";
  const isLg = isMd || getWidthClass() === "lg";
  const widthHelpers = {
    isXs,
    isSm,
    isMd,
    isLg,
  };

  return {
    size: getWidthClass(),
    width,
    ...widthHelpers,
  };
};

export default useMediaQuery;
