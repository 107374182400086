import useMediaQuery from "../utils/useMediaQuery";
import HeroImage from "../components/HeroImage";
import ContainerWithTitle from "../components/Container/ContainerWithTitle";

function Graphics() {
  const { isXs, isSm } = useMediaQuery();
  const styles = {
    borderTop: "1px solid white",
    borderBottom: "1px solid white",
  };
  return (
    <section
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        display: isXs ? "block" : "flex",
      }}
    >
      <ContainerWithTitle title={"graphics"}>
        <p style={{ margin: "2rem 0" }}>
          If you are interested in graphic design work or art commissions,
          please inquire with the contact link below. If you are interested in
          supporting my art, please visit my etsy store coming very soon.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "1",
          }}
        >
          <h2 className="bolder m0">
            <a href="mailto:merryutran@gmail.com" className="secondary">
              inquiries
            </a>
          </h2>
          <h2 className="bolder m0" style={{ color: "gray" }}>
            {/* <a href="#" className="secondary"> */}
            etsy store (coming soon)
            {/* </a> */}
          </h2>
        </div>
      </ContainerWithTitle>
      <div
        style={{
          overflow: "auto",
        }}
      >
        <HeroImage
          width={isXs ? "100vw" : "50vw"}
          height={isSm ? "180px" : "250px"}
          src={"/images/graphics/cclogo.svg"}
          alt={"chai chi menu"}
          minImgHeight={isSm ? "120px" : "250px"}
          pin="center"
          styles={styles}
        />
        <HeroImage
          width={isXs ? "100vw" : "50vw"}
          height={isXs ? "600px" : "650px"}
          pin="center"
          src={"/images/graphics/ccmenu.svg"}
          alt={"chai chi menu"}
          minImgHeight={isSm ? "250px" : "400px"}
          styles={styles}
        />
        <HeroImage
          width={isXs ? "100vw" : "50vw"}
          height={isXs ? "600px" : "900px"}
          pin="center"
          src={"/images/graphics/lox.webp"}
          alt={"live laugh lox"}
          minImgHeight={isSm ? "250px" : "900px"}
          styles={styles}
        />
      </div>
    </section>
  );
}

export default Graphics;
