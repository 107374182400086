import "../styles/App.scss";
import useMediaQuery from "../utils/useMediaQuery";
import HeroImage from "../components/HeroImage";
import IconRow from "../components/IconRow";

function Navigation() {
  const { isMd, isSm } = useMediaQuery();
  let size = isMd ? 50 : 60;
  if (isSm) size = 40;
  const style = { fontSize: size, lineHeight: 1 };
  return (
    <div>
      <h4 className="bolder" style={{ margin: "10px 0" }}>
        Click below to view my work
      </h4>
      <a href="/graphics">
        <h1 className="bolder m0 link" style={style}>
          ART PROJECTS
        </h1>
      </a>
      <a href="/elearning">
        <h1 className="bolder m0 link" style={style}>
          E-LEARNING
        </h1>
      </a>
      <a href="/photography">
        <h1 className="bolder m0 link" style={style}>
          PHOTOGRAPHY
        </h1>
      </a>
      <a href="/videography">
        <h1 className="bolder m0 link" style={style}>
          VIDEOGRAPHY
        </h1>
      </a>
    </div>
  );
}

function NestFriendsCTA() {
  return (
    <a href="#">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "0.25rem",
        }}
      >
        <img src="/images/nestfriends.svg" width={150} alt="nestfriend logo" />
        <h4
          className="bolder"
          style={{ margin: " 0", width: "100%", textAlign: "left" }}
        >
          coming soon
        </h4>
        <h2 className="bolder m0 link">NESTFRIENDS.IO</h2>
      </div>
    </a>
  );
}

function App() {
  const { isXs, isSm, isMd, isLg } = useMediaQuery();
  let offsetX = isMd ? -360 : -125;
  if (isLg) {
    offsetX = -400;
  }
  console.log(offsetX);

  return (
    <section
      style={{
        height: "100vh",
        width: "100%",
        display: isXs ? "block" : "flex",
      }}
    >
      <HeroImage
        width={isXs ? "100%" : "60%"}
        src={"/images/merry.webp"}
        alt={"merry"}
        offsetX={offsetX}
        minImgHeight={"650px"}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            bottom: isXs ? 50 : 100,
            left: isXs ? 150 : 0,
            padding: "0px 20px",
          }}
        >
          <IconRow />

          <h1
            style={{
              color: "white",
              margin: 0,
              fontSize: isSm ? "65px" : "90px",
            }}
            className="bolder"
          >
            merry tran
          </h1>
          <a href="/about">
            <h2 className="bolder alt-text m0 link">about me</h2>
          </a>
        </div>
      </HeroImage>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          width: isXs ? "100vw" : "40vw",
          height: isXs ? "650px" : "100%",
        }}
      >
        <Navigation />
        <NestFriendsCTA />
      </div>
    </section>
  );
}

export default App;
