import useMediaQuery from "../utils/useMediaQuery";
import HeroImage from "../components/HeroImage";
import ContainerWithTitle from "../components/Container/ContainerWithTitle";

function Photography() {
  const { isXs, isMd } = useMediaQuery();
  const formUrl =
    "https://docs.google.com/forms/d/1g5MwE2b1tEuVb2awlaKAVJZylKSd7OLlXQRF1PUCk2U/edit ";
  const workUrl = "https://www.instagram.com/merry.fotos";
  const styles = {
    borderTop: "1px solid white",
    borderBottom: "1px solid white",
  };

  return (
    <section
      style={{
        height: "100%",
        width: "100%",
        display: isXs ? "block" : "flex",
        overflow: "hidden",
      }}
    >
      <ContainerWithTitle title={"photography"}>
        <p style={{ margin: "1.5rem 0" }}>
          I am currently open to inquiries! For rates or questions, please
          contact me via email or fill out the inquiry form below. I will
          usually get back to you within 24 hours
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "1",
          }}
        >
          <h2 className="bolder m0">
            <a
              href={formUrl}
              className="secondary"
              rel="noreferrer noopener"
              target="_blank"
            >
              inquiries
            </a>
          </h2>
          <h2 className="bolder m0">
            <a
              href={workUrl}
              className="secondary"
              rel="noreferrer noopener"
              target="_blank"
            >
              view work
            </a>
          </h2>
        </div>
      </ContainerWithTitle>
      <div
        style={{
          overflow: "auto",
          width: isXs ? "100%" : "50%",
        }}
      >
        <HeroImage
          width={"100%"}
          offsetY={isXs ? -225 : 0}
          pin="right"
          src={"/images/photography/1.webp"}
          alt={"jess-khiet"}
          minImgHeight={"1100px"}
          styles={styles}
        />
        <HeroImage
          width={"100%"}
          offsetX={isMd ? -240 : 0}
          src={"/images/photography/2.webp"}
          alt={"jk-church"}
          minImgHeight={"1100px"}
          styles={styles}
        />
        <HeroImage
          width={"100%"}
          offsetY={isXs ? -225 : -200}
          offsetX={isMd ? -240 : 0}
          src={"/images/photography/3.webp"}
          alt={"train"}
          minImgHeight={"1100px"}
          styles={styles}
        />
        <HeroImage
          width={"100%"}
          offsetY={0}
          offsetX={-450}
          src={"/images/photography/4.webp"}
          alt={"baby"}
          styles={styles}
        />
      </div>
    </section>
  );
}

export default Photography;
