import useMediaQuery from "../../utils/useMediaQuery";

function HeroImage({
  width = "60vw",
  src,
  alt,
  children,
  offsetX = 0,
  offsetY = 0,
  minImgHeight,
  height,
  pin = "left",
  styles,
}) {
  const { isXs } = useMediaQuery();
  let ht = height;

  if (!height) {
    ht = isXs ? "60vh" : "100vh";
  }

  const horizontalAlignment = () => {
    if (pin === "left") return { left: offsetX };
    if (pin === "right") return { right: offsetX };
    return { left: "50%", transform: "translateX(-50%)" };
  };

  return (
    <div
      style={{
        width,
        position: "relative",
        height: ht,
        overflow: "hidden",
        ...styles,
      }}
    >
      {children}

      <div
        style={{
          position: "absolute",
          minWidth: "100%",
          top: offsetY,
          ...horizontalAlignment(),
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{
            height: ht,
            minHeight: minImgHeight,
            width: "auto",
          }}
        />
      </div>
    </div>
  );
}

export default HeroImage;
