import React, { useState, useEffect } from "react";
import "../styles/Resume.scss";
import { FaDownload } from "react-icons/fa6";

export default function Resume() {
  const url = "https://d13b2cruxwxefb.cloudfront.net/";
  const fileName = "merry-resume";
  const [site, setSite] = useState("");

  async function fetchSite() {
    const res = await fetch(`${url}${fileName}.html`);
    const data = await res.text();
    setSite(data);
  }

  useEffect(() => {
    fetchSite();
  }, []);

  return (
    <section className="Resume--container">
      <div className="Resume--download">
        <a href={`${url}${fileName}.pdf`}>
          <FaDownload size={50} />
        </a>
      </div>
      <div dangerouslySetInnerHTML={{ __html: site }}></div>
    </section>
  );
}
