import {
  Card,
  CardContent,
  CardHeader,
  CardImage,
} from "../../components/Card";
import classNames from "classnames";
import "../../styles/Grid.scss";
import "../../styles/Card.scss";
import { IoMdArrowDropleft } from "react-icons/io";

function Grid({ data }) {
  const { title, description, content, options } = data;
  if (!title || !description || !content) return null;

  const gridClasses = classNames("Grid--grid", {
    "Grid--grid__columns": options?.twinColumns,
  });

  return (
    <section className="Grid--container">
      <a
        href="/"
        style={{ display: "flex", alignItems: "center", margin: "5px -33px" }}
      >
        <IoMdArrowDropleft size={35} />
        <h4 className="bolder m0 link">BACK TO HOME</h4>
      </a>
      <h2 className="bolder m0" style={{ margin: "10px 0" }}>
        {title}
      </h2>
      <p>{description}</p>
      <div className={gridClasses}>
        {content.map(({ uri, title, description, imageUri }) => (
          <Card key={title}>
            {imageUri && <CardImage src={imageUri} alt={title} />}
            <CardContent>
              <CardHeader>{title}</CardHeader>
              <p>{description}</p>
              {/* {uri &&
                                <CardLink href={uri}>
                                    demo <FaExternalLinkAlt fill='white' stroke='white' size={'.8rem'} />
                                </CardLink>
                            } */}
            </CardContent>
          </Card>
        ))}
      </div>
    </section>
  );
}

export default Grid;
