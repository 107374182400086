import { FaLinkedin } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
import { Tooltip } from "react-tooltip";

function IconRow() {
  const buttonStyle = {
    backgroundColor: "var(--white)",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  let size = 33;
  const links = [
    {
      name: "Resume",
      icon: <IoDocumentText size={size} />,
      url: "/resume",
    },
    {
      name: "LinkedIn",
      icon: <FaLinkedin size={size} />,
      url: "https://www.linkedin.com/in/merry-tran/",
    },
  ];

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {links.map((link) => {
        return (
          <a
            style={buttonStyle}
            data-tooltip-content={link.name}
            data-tooltip-id="tooltip"
            data-tooltip-place="top"
            key={link.name}
            href={link.url}
            rel="noreferrer noopener"
            target="_blank"
          >
            {link.icon}
          </a>
        );
      })}
      <Tooltip id="tooltip" />
    </div>
  );
}

export default IconRow;
