const Container = ({ width, height, children }) => {
  return (
    <div
      style={{
        width,
        height,
        padding: "0 20px ",
      }}
    >
      {children}
    </div>
  );
};

export default Container;
